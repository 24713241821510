<template>
<div class="company-row">
  <b-container class="container--company">
    <div class="company-row__listing">
      <CompanyItem :company="item" v-for="item in company" :key="item.title"/>
    </div>
  </b-container>
</div>
</template>

<script>
import CompanyItem from '@/components/CompanyItem'

export default {
  name: 'Mcip-CompanyRow',
  props: {
    company: Array
  },
  components: {
    CompanyItem
  }
}
</script>

<style lang="scss" scoped>
.company-row {
  margin-top: calc(80em / 18);

  &__listing {
  }
}
</style>
