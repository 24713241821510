<template>
<div class="article-img">
  <b-container class="container--article">
    <img class="article-img__img" :src="img" :alt="alt">
  </b-container>
</div>
</template>

<script>
export default {
  name: 'Mcip-ArticleImg',
  props: {
    img: String,
    alt: String
  }
}
</script>

<style lang="scss" scoped>
.article-img {
  margin-bottom: calc(60em / 18);


  > .container {

  }

  &__img {
    width: inherit;
  }
}
</style>
