<template>
  <footer class="footer">
    <b-container>
      <div class="footer-upper">
        <a href="/" class="footer-upper-img">
          <img :src="response.img.src" :alt="response.img.src">
        </a>
        <div class="footer-upper-nav">
          <div class="footer-upper-nav__item" v-for="item in response.nav" :key="item.href">
            <a :href="item.href" class="footer-upper-nav__link" :class="item.style" :target="item.target">
              {{ item.text }}
            </a>
          </div>
        </div>
        <div class="footer-upper-office">
          <div class="office" v-for="office in response.offices" :key="office.name">
            <div class="office-name">
              {{ office.name }}
            </div>
            <div class="office-location">
              {{ office.address1 }}
            </div>
            <div class="office-location">
              {{ office.address2 }}
            </div>
            <div class="office-location">
              {{ office.address3 }}
            </div>
            <a :href="`tel:${office.tel}`" class="office-tel">
              {{ office.tel }}
            </a>
            <a :href="`mailto:${office.email}`" class="office-email">
              {{ office.email }}
            </a>
          </div>
        </div>
      </div>
      <div class="footer-lower">
        <div class="footer-lower-copyright">
          © Copyright {{ currentYear }} Multiple Capital Investment Partners. All rights reserved. <a href="/disclaimer">Disclaimer</a> & <a href="/privacy-policy">Privacy Policy</a>. Website by <a href="https://jump.com.hk/" target="_blank">Jump</a>.
        </div>
        <div class="footer-lower-disclaimer">
          <a href="/disclaimer">
            Disclaimer
          </a>
        </div>
      </div>
    </b-container>
  </footer>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Mcip-Footer',
  data(){
    return ({
      response: {}
    })
  },
  computed: {
    currentYear(){
      return new Date().getFullYear();
    }
  },
  methods: {
    fetchFooter(){
      axios.get('/navigation/footer').then(
        res=>{
          this.response = res.data
        }
      )
    }
  },
  created(){
    this.fetchFooter();
  }
}
</script>

<style lang="scss">
:root {
  --bg-footer: #F8F8F8;
}

.footer {
  background-color: var(--bg-footer);

  &-upper {
    display: grid;
    grid-template-columns: calc(280em / 18) calc(200em / 18) auto;
    grid-column-gap: calc(30em / 18);

    padding-top: calc(60em / 18);
    padding-bottom: calc(55em / 18);

    @media only screen and (max-width: 1200px){
      display: grid;
      padding-top: calc(30em / 16);
      grid-template-columns: calc(280em / 18) auto;
    }

    @media only screen and (max-width: 768px){
      display: block;
      margin-bottom: calc(30em / 18);
    }

    &-img {
      margin-top: calc(-30em / 18);
      display: block;

      @media only screen and (max-width: 1200px){
        height: calc(58em / 16);
        margin-top: 0;
        grid-column: span 2;
        margin-bottom: calc(30em / 18);
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: top left;
      }
    }

    &-nav {
      @media only screen and (max-width: 768px){
        margin-bottom: calc(30em / 18);
      }

      &__item {
        margin-bottom: calc(7em / 18);
      }

      &__link {
        color: var(--text);
        font-size: calc(14em / 18);
        font-weight: 500;

        text-transform: uppercase;
        transition: .3s;

        &:hover {
          color: var(--lime);
          text-decoration: none;
        }
      }
    }

    &-office {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-row-gap: calc(30em / 18);

      @media only screen and (max-width: 576px){
        display: block;
      }
    }
  }

  &-lower {
    border-top: 4px solid var(--lime);
    width: 100%;

    font-family: sans-serif;
    font-size: calc(12em / 18);

    min-height: calc(46em / 12);
    padding: calc(10em / 18) 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 576px){
      border: none;
      display: grid;
      grid-template-columns: 100%;
    }

    a {
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }

    &-copyright {
      @media only screen and (max-width: 576px){
        order: 2;

        border-top: 4px solid var(--lime);
        padding-top: calc(11em / 16);
        margin-top: calc(11em / 16);
      }
    }

    &-disclaimer {
      @media only screen and (max-width: 576px){
        order: 1;
      }
    }
  }
}

.office {
  max-width: calc(200em / 18);

  @media only screen and (max-width: 576px){
    margin-bottom: calc(15em / 18);
    max-width: none;
  }

  &-name {
    font-weight: 500;
    font-size: calc(14em / 18);

    @media only screen and (max-width: 768px){
      color: var(--lime);
      font-size: calc(14em / 16);
    }
  }

  &-location {
    font-weight: 300;
    font-size: calc(12em / 18);
    color: var(--text);
    display: block;

    @media only screen and (max-width: 768px){
      font-size: calc(12em / 16);
    }
  }

  &-tel {
    font-weight: 300;
    font-size: calc(12em / 18);
    color: var(--text);
    display: block;

    @media only screen and (max-width: 768px){
      font-size: calc(12em / 16);
    }
  }

  &-email {
    font-weight: 300;
    font-size: calc(12em / 18);
    color: var(--text);
    display: block;

    @media only screen and (max-width: 768px){
      font-size: calc(12em / 16);
    }
  }
}
</style>
