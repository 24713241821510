<template>
<div class="team-row">
  <b-container>
    <People v-for="ppl in team" :ppl="ppl" :key="ppl.name"/>
  </b-container>
</div>
</template>

<script>
import People from '@/components/People'
export default {
  name: 'Mcip-TeamRow',
  props: {
    team: Array
  },
  components: {
    People
  }
}
</script>

<style lang="scss" scoped>
.team-row {
  &__listing {
  }
}
</style>
