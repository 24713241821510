<template>
<div class="main-banner">
  <div class="main-banner-img">
    <img :src="img" :alt="title" class="main-banner-img__img">
  </div>
  <div class="main-banner-overlay">
    <h1 class="main-banner-overlay__title">
      {{ title }}
    </h1>
    <div class="main-banner-overlay__text">
      {{ text }}
    </div>
    <button class="main-banner-overlay__btn btn btn--clear" @click="scrollOver">
      <img src="@/assets/img/icons/arrow-down--white.svg" alt="scroll down">
    </button>
  </div>
</div>
</template>

<script>
export default {
  name: 'Mcip-MainBanner',
  props: {
    img: String,
    title: String,
    text: String
  },
  methods: {
    scrollOver(){
      var header = document.getElementsByTagName('header')[0].offsetHeight;
      var start = this.$el.offsetTop;
      var height = this.$el.offsetHeight;
      var endOfEl = start + height - header; //minus header for sticky purpose
      window.scrollTo({
        top: endOfEl,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.main-banner {
  width: 100%;
  height: calc(100vh - 112em / 18);
  position: relative;

  @media only screen and (max-width: 1200px){
    height: calc(100vh - 65em / 16);
  }

  &-img {
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(31, 44, 92, .5);
    color: var(--white);


    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    > * {
      max-width: calc(690rem / 18);
    }

    &__title {
      margin-top: 15vh;
      margin-bottom: 4vh;
      padding: 0 15px;

      @media only screen and (max-width: 768px){
        margin-top: 0;
      }
    }

    &__text {
      padding: 0 15px;
    }

    &__btn {
      position: absolute;
      bottom: 5vh;

      animation: floating 1s infinite;
    }
  }
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50%);
  }
  0% {
    transform: translateY(0);
  }
}
</style>
