<template>
  <div class="nav">
    <div class="nav-btn">
      <button class="btn btn--clear" v-b-toggle.nav-drawer>
        <img src="@/assets/img/icons/menu--lime.svg" alt="menu">
      </button>
    </div>
    <div class="nav-drawer">
      <b-sidebar no-header no-footer id="nav-drawer">
        <b-container>
          <a class="nav-drawer-logo" href="/">
            <img :src="img.src" :alt="img.src">
          </a>
          <div class="nav-drawer-btn">
            <button class="btn btn--clear" v-b-toggle.nav-drawer>
              <img src="@/assets/img/icons/cross--white.svg" alt="close">
            </button>
          </div>
          <div class="nav-drawer-nav">
            <div class="nav-drawer-nav__item" v-for="item in nav" :key="item.href">
              <a :href="item.href" class="nav-drawer-nav__link" :class="[activeRouteHelper(item.href), item.style]" :target="item.target">
                {{ item.text }}
              </a>
            </div>
          </div>
        </b-container>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: Object,
    nav: Array
  },
  name: 'Mcip-Nav',
  methods: {
    activeRouteHelper(href){
      return this.$route.path === href ? 'active' : '';
    }
  }
}
</script>

<style lang="scss">
#nav-drawer {
  width: 100%;
  background-color: var(--blue) !important;
  padding-top: calc(17em / 16);

  .sidebar {
    &-body {
      width: 100%;
    }
  }
}

.nav-drawer {
  .container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr calc(30em / 16);
    grid-template-rows: calc(58em / 16) 1fr;
    padding-left: calc(34em / 16);
  }


  &-logo {
    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      object-position: left center;
    }
  }

  &-btn {

  }

  &-nav {
    grid-column: span 2;
    padding-top: calc(55 / 570 * 100vh);

    &__item {
      margin-bottom: calc(25em / 16);
    }

    &__link {
      color: var(--white);
      font-size: calc(20em / 16);
      transition: .3s;

      &:hover {
        color: var(--lime);
        text-decoration: none;
      }

      &.btn {
        margin-top: calc(25rem / 16);
      }

      &.active {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 0;
          border-bottom: 4px solid var(--lime);
        }
      }
    }
  }
}
</style>
