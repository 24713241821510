<template>
<div class="text-text">
  <b-container class="container--article">
    <div class="text-text__left" v-html="left"></div>
    <div class="text-text__right" v-html="right"></div>
  </b-container>
</div>
</template>

<script>
export default {
  name: 'Mcip-TextText',
  props: {
    left: String,
    right: String
  }
}
</script>

<style lang="scss" scoped>
.text-text {
  margin-bottom: calc(60em / 18);

  > .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: calc(22em / 18);

    @media only screen and (max-width: 768px){
      display: block;
    }
  }

  &__left {
    @media only screen and (max-width: 768px){
      margin-bottom: calc(20em / 18);
    }
  }

  &__right {

  }
}
</style>
