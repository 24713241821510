<template>
  <div class="error">
    <h1>
      404 Not Found
    </h1>
  </div>
</template>

<script>
export default {
  name: 'Mcip-Error'
}
</script>

<style lang="scss" scoped>
.error {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    margin-bottom: 0;
  }
}
</style>
