<template>
  <div ref="gmap" class="google-map"></div>
</template>

<script>
export default {
  name: 'Mcip-GoogleMap',
  props: {
    lat: Number,
    lng: Number,
    mklat: Number,
    mklng: Number,
    title: String,
    loaded: Boolean
  },
  data(){
    return ({
      icon: "/img/icons/marker--lime.svg",
      style:
        [
            {
                "featureType": "administrative",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": "-100"
                    }
                ]
            },
            {
                "featureType": "administrative.province",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 65
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": "50"
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": "-100"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "all",
                "stylers": [
                    {
                        "lightness": "30"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "all",
                "stylers": [
                    {
                        "lightness": "40"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "hue": "#ffff00"
                    },
                    {
                        "lightness": -25
                    },
                    {
                        "saturation": -97
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels",
                "stylers": [
                    {
                        "lightness": -25
                    },
                    {
                        "saturation": -100
                    }
                ]
            }
        ]
    })
  },
  computed: {
    marker(){
      return { lat: this.mklat, lng: this.mklng };
    },
    location(){
      return { lat: this.lat, lng: this.lng };
    },
  },
  watch: {
    loaded(boolean){
      if (boolean) {
        this.initGmap();
      }
    }
  },
  methods: {
    initGmap(){
      // eslint-disable-next-line
      const map = new google.maps.Map(this.$refs.gmap, {
        zoom: 17,
        center: this.location,
        styles: this.style
      });

      // eslint-disable-next-line
      new google.maps.Marker({
        position: this.marker,
        map,
        title: this.title,
        icon: this.icon
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.google-map {
  width: 100%;
  height: 100%;
}
</style>
