<template>
<div class="img-text" :class="mod && `img-text--${mod}`">
  <b-container>
    <div class="img-text-box">
      <h3 class="img-text-box__title">
        {{title}}
      </h3>
      <div class="img-text-box__text" v-html="text"></div>

      <a :href="btn.href" class="img-text-box__btn btn btn--square">
        {{ btn.label }}
      </a>
    </div>
    <div class="img-text-img">
      <img class="img-text-img__img" :src="img" :alt="title">
    </div>
  </b-container>
</div>
</template>

<script>
export default {
  name: 'Mcip-ImgText',
  props: {
    title: String,
    text: String,
    img: String,
    btn: Object,
    mod: String
  }
}
</script>

<style lang="scss" scoped>
.img-text {
  margin-bottom: calc(90em / 18);

  > .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media only screen and (max-width: 768px){
      grid-template-columns: 100%;
    }
  }

  &-box{
    background-color: #F8F8F8;
    padding: calc(70em / 18) calc(40em / 18) calc(65em / 18) calc(75em / 18);

    @media only screen and (max-width: 768px){
      order: 2;
      padding: calc(33em / 18) calc(20em / 18) calc(38em / 18) calc(20em / 18);
    }

    &__title {
      color: var(--lime);
      font-weight: 600;
      margin-bottom: calc(33rem / 18);
    }

    &__text {
      width: calc(406em / 18);
      max-width: 100%;
      margin-bottom: calc(33rem / 18);

      @media only screen and (max-width: 768px){
        width: 100%;
      };
    }

    &__btn {

    }
  }

  &-img {
    @media only screen and (max-width: 768px){
      order: 1;
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--figma {
    background-color: var(--bg--green);
    margin-bottom: 0;

    > .container {
      max-width: 1440px;
      padding: 0;
    }

    .img-text {
      &-box {
        background-color: unset;
        padding-left: calc(124em / 18);

        @media only screen and (max-width: 768px){
          order: 1;
          padding-left: calc(20em / 18);
        }
      }

      &-img {
        @media only screen and (max-width: 768px){
          order: 2;
        }
      }
    }
  }
}
</style>
