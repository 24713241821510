<template>
<div class="home-investments">
  <b-container>
    <div class="home-investments-title">
      {{ title }}
    </div>
    <div class="home-investments-btn">
      <a :href="btn.href" class="btn btn--square">
        {{ btn.label }}
      </a>
    </div>
    <div class="home-investments-listing">
      <div class="investment-grid" v-for="grid in listing" :key="grid">
        <div class="investment-grid-img">
          <img :src="grid.img" alt="grid.title" class="investment-grid-img__img">
        </div>
        <div class="investment-grid-info">
          <h3 class="investment-grid-info__title">
            {{ grid.title }}
          </h3>
          <h4 class="investment-grid-info__location">
            {{ grid.location }}
          </h4>
        </div>
      </div>
    </div>
  </b-container>
</div>
</template>

<script>
export default {
  name: 'Mcip-HomeInvestments',
  props: {
    title: String,
    btn: Object,
    listing: Array
  }
}
</script>

<style lang="scss" scoped>
.home-investments {
  margin-bottom: calc(90em / 18);

  > .container {
    display: grid;
    grid-template-columns: 1fr minmax(calc(151em / 18), auto);
    grid-template-rows: calc(56em / 18) auto;
    grid-row-gap: calc(20em / 18);

    @media only screen and (max-width: 576px){
      grid-template-columns: 100%;
    }
  }

  &-title {
    font-size: calc(40em / 18);
    font-weight: 500;
    line-height: calc(62 / 40);
    color: var(--lime);

    @media only screen and (max-width: 768px){
      font-size: calc(21em / 18);
      font-weight: 500;
      line-height: calc(62 / 21);
    }
  }

  &-btn {
    @media only screen and (max-width: 576px){
      order: 3;
      text-align: center;
    }
  }

  &-listing {
    grid-column: span 2;

    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: calc(20em / 18);

    @media only screen and (max-width: 576px){
      grid-template-columns: 100%;
    }
  }
}

.investment-grid {
  display: grid;
  grid-template-rows: calc(295em / 18) auto;

  @media only screen and (max-width: 576px){
    margin-bottom: calc(15em / 16);
  }

  &-img {
    height: calc(295em / 18);
    width: 100%;
    background: #eee;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-info {
    border: 1px solid var(--blue);
    border-top: 0;
    padding: calc(20em / 18) calc(25em / 18);

    &__title {
      color: var(--lime);
    }

    &__location {
      color: var(--blue);
    }
  }
}
</style>
