<template>
  <header class="header" :class="{'header--sticky': sticky}" v-if="response">
    <b-container class="container--header">
      <a class="header-img" href="/">
        <img :src="response.img.src" :alt="response.img.alt">
      </a>
      <div class="header-nav">
        <div class="header-nav__item" :class="activeRouteHelper(item.href)" v-for="item in response.nav" :key="item.href">
          <a :href="item.href" class="header-nav__link" :class="item.style" :target="item.target">
            {{ item.text }}
          </a>
        </div>
      </div>
      <div class="header-menu">
        <Nav :img="response.mobileImg" :nav="response.nav"/>
      </div>
    </b-container>
  </header>
</template>

<script>
import Nav from '@/components/Nav'
import axios from 'axios'

export default {
  name: 'Mcip-Header',
  props: {
    sticky: {
      type: Boolean,
      default: ()=>false
    }
  },
  components: {
    Nav
  },
  data(){
    return ({
      response: {}
    })
  },
  methods: {
    fetchHeader(){
      axios.get('/navigation/header').then(
        res=>{
          this.response = res.data;
        }
      )
    },
    activeRouteHelper(href){
      return this.$route.path === href ? 'active' : '';
    }
  },
  created(){
    this.fetchHeader();
  }
}
</script>

<style lang="scss">
:root {
  --bg-header: #F8F8F8;
}

.header {
  width: 100%;
  background-color: var(--bg-header);
  height: calc(112em / 18);

  @media only screen and (max-width: 1200px){
    height: calc(78em / 16)
  }

  > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &-img {
    max-height: calc(80em / 18);

    @media only screen and (max-width: 1200px){
      max-height: calc(58em / 16)
    }

    img {
      width: 100%;
      height: 100%;
      max-width: inherit;
      max-height: inherit;
      object-fit: contain;
      object-position: left center;
    }
  }

  &-nav {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 1200px){
      display: none;
    }

    &__item {
      &:nth-child(n+2) {
        margin-left: calc(42em / 18);
      }

      &:last-child {
        &:after {
          content: none !important;
        }
      }

      &.active {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          bottom: -8px;
          left: 0;
          width: 100%;
          height: 100%;
          border-bottom: 4px solid var(--lime);
        }
      }
    }

    &__link {
      color: var(--blue);
      font-size: calc(14em / 18);
      font-weight: 500;
      transition: .3s;

      &.btn {
        margin-left: calc(-12em / 18);
      }

      &:hover {
        text-decoration: none;
        color: var(--lime);
      }
    }
  }

  &-menu {
    display: none;

    @media only screen and (max-width: 1200px){
      display: block;
    }
  }

  &--sticky {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1024;
  }
}
</style>
