<template>
<div class="company-slider">
  <b-container>
    <div class="company-slider-title">
      {{ title }}
    </div>
    <div class="company-slider-btn">
      <a :href="btn.href" class="btn btn--square">
        {{ btn.label }}
      </a>
    </div>
    <div class="company-slider-slider">
      <VueSlickCarousel v-bind="settings">
        <div class="company-slider-slider__item" v-for="company in listing" :key="company.img">
          <!-- <img class="company-slider-slider__img" src="https://via.placeholder.com/200x60" :alt="company.title"> -->
          <img class="company-slider-slider__img" :src="company.img" :alt="company.title">
        </div>
      </VueSlickCarousel>
    </div>
  </b-container>
</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  name: 'Mcip-CompanySlider',
  props: {
    title: String,
    btn: Object,
    listing: Object
  },
  components: {
    VueSlickCarousel
  },
  data(){
    return ({
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        pauseOnHover: false,
        autoplaySpeed: 1,
        speed: 20000,
        cssEase: "linear"
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.company-slider {
  > .container {
    display: grid;
    grid-template-columns: 1fr minmax(calc(151em / 18), auto);
    grid-template-rows: calc(56em / 18) auto;
  }

  &-title {
    font-size: calc(40em / 18);
    font-weight: 500;
    line-height: calc(62 / 40);
    color: var(--lime);

    @media only screen and (max-width: 768px){
      font-size: calc(21em / 18);
      font-weight: 500;
      line-height: calc(62 / 21);
    }
  }

  &-btn {
    @media only screen and (max-width: 768px){
      display: none;
    }
  }

  &-slider {
    grid-column: span 2;

    width: calc(1200em / 18);
    max-width: calc(100vw - 30px);
    padding-top: calc(50em / 18);
    margin-top: calc(10em / 18);
    border-top: 1px solid var(--text);
    margin-bottom: calc(80em / 18);

    &__item {
      padding: 0 calc(20em / 18);
      height: calc(60em / 16);
    }

    &__img {
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
