<template>
  <div class="intro" :class="`intro--${mod}`">
    <b-container>
      <h3 class="intro__title">{{ title }}</h3>
      <p class="intro__text" v-html="text"></p>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Mcip-Intro',
  props: {
    title: String,
    text: String,
    mod: String,
    width: String //with CSS unit
  }
}
</script>

<style lang="scss" scoped>
.intro {
  max-width: v-bind(width);
  text-align: center;
  margin: 0 auto calc(50em / 18);
  margin-top: calc(70em / 18);

  &__title {
    margin-bottom: calc(50rem / 18);
  }

  &__text {
    font-size: calc(20rem / 18);
    font-weight: 500;
  }

  &--lime {
    .intro {
      &__title {
        color: var(--lime);
      }
    }
  }
}
</style>
