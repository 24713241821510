<template>
<div class="desc">
  <b-container class="container--article">
    <h2 class="desc__title">
      {{ title }}
    </h2>
    <a :href="btn.href" class="desc__btn btn btn--square">
      {{ btn.label }}
    </a>
  </b-container>
</div>
</template>

<script>
export default {
  name: 'Mcip-Description',
  props: {
    title: String,
    btn: Object
  }
}
</script>

<style lang="scss" scoped>
.desc {
  text-align: center;
  margin-top: calc(95em / 18);
  margin-bottom: calc(65em / 18);

  &__title {
    font-weight: 400;
    font-size: calc(25rem / 18);
    line-height: calc(40 / 25);
    margin-bottom: calc(40rem / 18);
  }

  &__btn {

  }
}
</style>
