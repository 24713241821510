<template>
  <div>
    <Banner :banner="response.banner"/>
    <CompanyRow :company="response.companyRow"/>
  </div>
</template>

<script>
import axios from 'axios'
import Banner from '@/components/Banner'
import CompanyRow from '@/components/CompanyRow'

export default{
  name: 'Mcip-Partners',
  metaInfo() {
    let meta = this.response.meta;
    return {
      title: meta ? meta.title : 'mcip',
      meta: [
        {
          name: 'description',
          content: meta ? meta.description : 'mcip'
        },
        {
          name: 'keywords',
          content: meta ? meta.keywords : 'mcip'
        },
        {
          proprty: 'og:image',
          content: meta ? meta.img : 'mcip'
        },
      ]
    }
  },
  components: {
    Banner,
    CompanyRow
  },
  data(){
    return ({
      response: {}
    })
  },
  methods: {
    fetchPageContent(){
      axios.get('/partner/getContent').then(
        res=>{
          this.response = res.data;
        }
      )
    }
  },
  created(){
    this.fetchPageContent()
  }
}
</script>

<style lang="scss">

</style>
