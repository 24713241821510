<template>
  <article class="page" :class="slug">
    <template v-for="(obj, index) in response">
      <Banner v-if="getKey(obj) === 'banner'" :banner="obj.banner" :key="getKey(obj) + index"/>
      <Intro v-else-if="getKey(obj) === 'intro'" :title="obj.intro.title" :text="obj.intro.text" width="1025px" :key="getKey(obj) + index"/>
      <TextText v-else-if="getKey(obj) === 'textText'" :left="obj.textText.left" :right="obj.textText.right" :key="getKey(obj) + index"/>
      <ArticleImg v-else-if="getKey(obj) === 'articleImg'" :img="obj.articleImg.img" :alt="obj.articleImg.alt" :key="getKey(obj) + index"/>
      <!-- {{ getKey(obj) }} -->
    </template>
  </article>
</template>

<script>
import axios from 'axios'
import Banner from '@/components/Banner'
import Intro from '@/components/Intro'
import TextText from '@/components/TextText'
import ArticleImg from '@/components/ArticleImg'

export default {
  name: 'Mcip-page',
  metaInfo() {
    let meta = this.response.meta;
    return {
      title: meta ? meta.title : 'mcip',
      meta: [
        {
          name: 'description',
          content: meta ? meta.description : 'mcip'
        },
        {
          name: 'keywords',
          content: meta ? meta.keywords : 'mcip'
        },
        {
          proprty: 'og:image',
          content: meta ? meta.img : 'mcip'
        },
      ]
    }
  },
  data(){
    return ({
      response: []
    })
  },
  components: {
    Banner,
    Intro,
    TextText,
    ArticleImg
  },
  computed: {
    slug(){
      return this.$route.params.slug;
    }
  },
  methods: {
    getKey(obj){
      return Object.keys(obj)[0]
    },
    fetchPageContent(){
      axios.get('/page/getContent?slug=' + this.slug).then(
        res=>{
          this.response = res.data;
        }
      ).catch(
        ()=>{
          this.$router.push('/404');
        }
      )
    }
  },
  created(){
    this.fetchPageContent()
  }
}
</script>

<style lang="scss">
.page {
  margin-bottom: calc(80em / 18);
}
</style>
