<template>
<div class="home">
  <MainBanner :img="response.mainBanner.img" :title="response.mainBanner.title" :text="response.mainBanner.text" />
  <Description :title="response.desc.title" :btn="response.desc.btn" />
  <ImgText :title="response.imgText.title" :text="response.imgText.text" :img="response.imgText.img" :btn="response.imgText.btn" />
  <HomeInvestments :title="response.investments.title" :btn="response.investments.btn" :listing="response.investments.listing" />
  <CompanySlider :title="response.partners.title" :btn="response.partners.btn" :listing="response.partners.listing" />
  <ImgText :title="response['imgText--figma'].title" :text="response['imgText--figma'].text" :img="response['imgText--figma'].img" :btn="response['imgText--figma'].btn" mod="figma"/>
</div>
</template>

<script>
import axios from 'axios'
import MainBanner from '@/components/MainBanner'
import Description from '@/components/Description'
import ImgText from '@/components/ImgText'
import HomeInvestments from '@/components/HomeInvestments'
import CompanySlider from '@/components/CompanySlider'

export default {
  name: 'Mcip-Home',
  metaInfo() {
    let meta = this.response.meta;
    return {
      title: meta ? meta.title : 'mcip',
      meta: [
        {
          name: 'description',
          content: meta ? meta.description : 'mcip'
        },
        {
          name: 'keywords',
          content: meta ? meta.keywords : 'mcip'
        },
        {
          proprty: 'og:image',
          content: meta ? meta.img : 'mcip'
        },
      ]
    }
  },
  components: {
    MainBanner,
    Description,
    ImgText,
    HomeInvestments,
    CompanySlider
  },
  data(){
    return ({
      response: {}
    })
  },
  methods: {
    fetchPageContent(){
      axios.get('/home/getContent').then(
        res=>{
          this.response = res.data;
        }
      )
    }
  },
  created(){
    this.fetchPageContent()
  }
}
</script>

<style lang="scss">

</style>
