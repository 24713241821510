import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'

import "@/assets/scss/root.scss"
import "@/assets/scss/grid.scss"
import "@/assets/scss/typo.scss"
import "@/assets/scss/button.scss"

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
