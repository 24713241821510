<template>
  <div>
    <Banner :banner="response.banner"/>
    <KeyValue :list="response.keyValue" />
    <TeamRow :team="response.teamRow"></TeamRow>
  </div>
</template>

<script>
import axios from 'axios'
import Banner from '@/components/Banner'
import KeyValue from '@/components/KeyValue'
import TeamRow from '@/components/TeamRow'

export default{
  name: 'Mcip-Investments',
  metaInfo() {
    let meta = this.response.meta;
    return {
      title: meta ? meta.title : 'mcip',
      meta: [
        {
          name: 'description',
          content: meta ? meta.description : 'mcip'
        },
        {
          name: 'keywords',
          content: meta ? meta.keywords : 'mcip'
        },
        {
          proprty: 'og:image',
          content: meta ? meta.img : 'mcip'
        },
      ]
    }
  },
  components: {
    Banner,
    KeyValue,
    TeamRow
  },
  data(){
    return ({
      response: {}
    })
  },
  methods: {
    fetchPageContent(){
      axios.get('/team/getContent').then(
        res=>{
          this.response = res.data;
        }
      )
    }
  },
  created(){
    this.fetchPageContent()
  }
}
</script>

<style lang="scss">

</style>
