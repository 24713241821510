<template>
<div class="key-value">
  <b-container>
    <div class="key-value-row" v-for="row in list" :key="row.key">
      <h3 class="key-value-row__key">
        {{ row.key }}
      </h3>
      <h4 class="key-value-row__value">
        {{ row.value }}
      </h4>
    </div>
  </b-container>
</div>
</template>

<script>
export default {
  name: 'Mcip-KeyValue',
  props: {
    list: Array
  },
}
</script>

<style lang="scss" scoped>
.key-value {
  margin-top: calc(78em / 18);
  margin-bottom: calc(60em / 18);
  padding-left: calc(100em / 18);

  @media only screen and (max-width: 1200px){
    padding-left: 0;
  }

  &-row {
    display: flex;

    @media only screen and (max-width: 768px){
      display: block;
    }
    
    & + & {
      margin-top: calc(57em / 18);
    }

    &__key {
      flex: 0 0 calc(226rem / 18);
      font-weight: 600;
    }

    &__value {
      flex: 1 1 auto;
      margin-left: calc(80em / 18);
      font-weight: 500;

      @media only screen and (max-width: 1200px){
        margin-left: calc(40em / 18);
      }

      @media only screen and (max-width: 768px){
        margin-left: 0;
      }
    }
  }
}
</style>
