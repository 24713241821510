import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

import Home from '../views/Home'
import Page from '../views/Page'
import Investments from '../views/Investments'
import Partners from '../views/Partners'
import Teams from '../views/Teams'
import Contact from '../views/Contact'
import Error from '../views/Error'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/investments',
    name: 'investments',
    component: Investments
  },
  {
    path: '/partners',
    name: 'partners',
    component: Partners
  },
  {
    path: '/teams',
    name: 'teams',
    component: Teams
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/404',
    name: '404',
    component: Error
  },
  {
    path: '/:slug',
    name: 'page',
    component: Page
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
