<template>
<div class="people">
  <div class="people-img">
    <img class="people-img__img" :src="ppl.img" :alt="ppl.name">
  </div>

  <div class="people-info">

    <h3 class="people-info-name">{{ ppl.name }}</h3>
    <h4 class="people-info-title">{{ ppl.title }}</h4>
    <h4 class="people-info-country">{{ ppl.country }}</h4>
    <a class="people-info-email" :href="`mailto:${ppl.email}`">{{ ppl.email }}</a>
    <div class="people-info-btn">
      <div class="people-info-btn__offset"></div>
      <button class="btn btn--clear people-info-btn__btn" @click="toggleIsOpen">
        <img v-if="isOpen" src="@/assets/img/icons/minus.svg" alt="collapse">
        <img v-else src="@/assets/img/icons/plus.svg" alt="expend">
      </button>
    </div>
  </div>


  <b-collapse v-model="isOpen">
    <div class="people-desc" v-html="ppl.desc"></div>
  </b-collapse>

</div>
</template>

<script>
export default {
  name: 'Mcip-People',
  props: {
    ppl: Object
  },
  data(){
    return ({
      isOpen: false
    })
  },
  methods: {
    toggleIsOpen(){
      this.isOpen = !this.isOpen;
    }
  }
}
</script>

<style lang="scss" scoped>
.people {
  display: grid;
  grid-template-columns: calc(362em / 18) 1fr;
  grid-column-gap: calc(44em / 18);
  margin-bottom: calc(50em / 18);
  padding: calc(20em / 18) calc(125em / 18) calc(25em / 18) calc(25em / 18);

  @media only screen and (max-width: 1200px){
    padding-right: calc(25em / 18);
    grid-column-gap: calc(25em / 18);
  }

  @media only screen and (max-width: 768px){
    display: block;
  }

  &:nth-child(odd){
    background-color: var(--bg--green);

    .people-info {
      border-color: var(--lime);

      &-btn__offset {
        border-color: var(--lime);
      }

      &-btn__btn {
        color: var(--lime);

        img {
          filter: invert(73%) sepia(65%) saturate(380%) hue-rotate(46deg) brightness(92%) contrast(79%);
        }
      }
    }
  }

  &:nth-child(even){
    color: var(--text);
    background-color: var(--bg--blue);

    .people-info-btn__btn {
      color: var(--text);
    }
  }

  &-img {
    grid-row: 1 / 99;
    max-height: calc(344em / 18);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: calc(362em / 18);
      max-height: calc(344em / 18);
    }

    @media only screen and (max-width: 768px){
      text-align: center;
    }
  }

  &-info {
    display: grid;
    grid-template-columns: 1fr calc(30em / 18);
    grid-column-gap: calc(30em / 18);
    padding-bottom: calc(10em / 18);

    height: calc(310em / 18);
    margin-bottom: calc(34em / 18);
    border-bottom: 4px solid;

    @media only screen and (max-width: 768px){
      display: block;
      margin-bottom: 0;
      border-bottom: 0;
      height: auto;
    }

    &-name {
      margin-top: calc(85rem / 18);
      margin-bottom: calc(40rem / 18);

      @media only screen and (max-width: 1200px){
        margin-top: calc(40rem / 18);
      }
    }

    &-title {
      margin-bottom: 0;
    }

    &-country {
      margin-bottom: calc(30rem / 18);
    }

    &-email {
      color: var(--text);
    }

    &-btn {
      grid-row: 1 / 99;
      grid-column: 2 / 3;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      @media only screen and (max-width: 768px){
        margin-top: calc(23em / 16);
      }

      &__offset {
        @media only screen and (max-width: 768px){
          flex: 1 0 50%;
          border-bottom: 4px solid;
          margin-right: calc(6em / 16);
        }
      }

      &__btn {
        border: 3px solid currentColor;
        width: calc(30rem / 18);
        height: calc(30rem / 18);
        border-radius: 0;
        padding: calc(16em / 18);
      }
    }
  }

  &-desc {

  }
}
</style>
