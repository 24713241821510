<template>
  <div class="company-item">
    <a :href="company.href" class="company-item-img" target="_blank">
      <img class="company-item-img__img" :src="company.img" :alt="company.title" />
    </a>
    <h3 class="company-item-title">
      {{ company.title }}
    </h3>
    <div class="company-item-text" :class="{'open': isOpen}" v-html="company.text"></div>

    <div class="company-item-btn">
      <button class="btn btn--clear" @click="toggleIsOpen">
        <img v-if="isOpen" src="@/assets/img/icons/minus--lime.svg" alt="close">
        <img v-else src="@/assets/img/icons/plus--lime.svg" alt="expand">
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Mcip-CompanyItem',
  props: {
    company: Object
  },
  data(){
    return ({
      isOpen: false
    })
  },
  methods: {
    toggleIsOpen(){
      this.isOpen = !this.isOpen;
    }
  }
}
</script>

<style lang="scss" scoped>
.company-item {
  border-bottom: 4px solid var(--lime);
  display: grid;
  grid-template-columns: calc(370 / 995 * 100%) auto calc(30em / 18);
  padding: calc(20em / 18);
  margin-bottom: calc(55em / 18);

  @media only screen and (max-width: 768px){
    display: block;
  }

  &-img {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
    margin-right: calc(50em / 18);
    max-height: 90px;

    @media only screen and (max-width: 768px){
      margin-right: 0;
      margin-bottom: calc(15em / 16);
      max-height: calc(100em / 16);
    }

    &__img {
      width: 100%;
      height: 100%;
      max-height: inherit;
      object-fit: contain;
      object-position: top center;
    }
  }

  &-title {

  }

  &-text {
    max-height: calc(115em / 18);
    overflow: hidden;
    transition: .7s linear;

    &.open {
      max-height: 999px;
    }
  }

  &-btn {
    grid-row: 1 / 3;
    grid-column: 3 / 4;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: calc(30em / 18);

    @media only screen and (max-width: 768px){
      margin-left: 0;
    }

    .btn {
      width: calc(30rem /  18);
      height: calc(30rem / 18);
      box-shadow: none;

      img {
        width: calc(30rem /  18);
        height: calc(30rem / 18);
        object-fit: contain;
      }
    }
  }
}
</style>
