<template>
  <div class="investments-listing">
    <b-container>
      <div class="listing">
        <div class="listing-grid" v-if="!isLoading">
          <div class="grid" v-for="grid in listing" :key="grid.title" @click="showModal(grid)">
            <div class="grid-img">
              <img class="grid-img__img" :src="grid.img" :alt="grid.alt">
            </div>
            <h3 class="grid__title">{{ grid.title }}</h3>
            <p class="grid__text">{{ grid.text }}</p>
          </div>
        </div>
        <div class="listing-loading" v-else-if="isLoading">
          <div class="grid" v-for="i in 6" :key="i">
            <div class="grid-img"></div>
            <div class="grid__title"></div>
            <div class="grid__text"></div>
          </div>
        </div>
        <div class="listing-pagination">
          <b-pagination limit="5" align="center" hide-ellipsis v-model="currentPage" :total-rows="maxPage" hide-goto-end-buttons per-page="1" @page-click="gotoTop"/>
        </div>
      </div>
    </b-container>

    <div class="modal">
      <b-modal v-model="modal.model" hide-header hide-footer centered dialog-class="investments-modal-dialog">
        <div class="modal-btn">
          <button class="btn btn--clear" @click="closeModal">
            <img src="@/assets/img/icons/cross--white.svg" alt="close">
          </button>
        </div>
        <h3 class="modal-title">
          {{ modal.title }}
        </h3>
        <h4 class="modal-subtitle">
          {{ modal.text }}
        </h4>
        <div class="modal-desc">
          <h4>Description</h4>
          <div v-html="modal.desc"></div>
        </div>
        <div class="modal-table">
          <h4>Investment Details</h4>
          <div class="modal-table-row" v-for="row in modal.table" :key="row.key">
            <h6 class="modal-table-row__key">
              {{ row.key }}
            </h6>
            <div class="modal-table-row__value">
              {{ row.value }}
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'InvestmentsListing',
  data(){
    return ({
      currentPage: 1,
      maxPage: 1,
      listing: [],
      isLoading: false,
      modal: {
        model: false
      }
    })
  },
  watch: {
    currentPage(){
      this.getListing();
    }
  },
  methods: {
    getListing(){
      this.isLoading = true

      var getParam = {
        currentPage: this.currentPage
      }

      getParam = new URLSearchParams(getParam).toString();

      axios.get('/investment/getListing' + '?' + getParam).then(
        res=>{
          var {currentPage, maxPage, listing} = res.data;
          this.currentPage = currentPage;
          this.maxPage = maxPage;
          this.listing = listing;
        }
      ).finally(
        ()=>{
          this.isLoading = false;
        }
      )
    },
    showModal(grid){
      this.modal = {
        model: true
      }
      Object.assign(this.modal, grid);
    },
    closeModal(){
      this.modal.model = false;
    },
    gotoTop(){
      // console.log('clicked')

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  },
  created(){
    this.getListing();
  }
}
</script>

<style lang="scss" scoped>
.investments-listing {
  margin-bottom: calc(50em / 18);
}

.listing {

  &-grid, &-loading {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: calc(20em / 18);
    grid-row-gap: calc(60em / 18);

    margin-bottom: calc(55em / 18);

    @media only screen and (max-width: 768px){
      grid-template-columns: 100%;
    }
  }

  &-loading {
    .grid {
      opacity: .7;


      &__title {
        width: calc(200em / 18);
        height: calc(40em / 18);
        background-color: var(--lime);
        margin-left: auto;
        margin-right: auto;
        border-radius: 20px;
      }

      &__text {
        width: calc(300em / 18);
        height: calc(27em / 18);
        background-color: var(--text);
        margin-left: auto;
        margin-right: auto;
        border-radius: 20px;
      }
    }
  }
}

.grid {
  text-align: center;
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: calc(104rem / 18);
    height: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 3px solid var(--lime);
  }

  &-img {
    overflow: hidden;
    background-color: var(--lime);
    width: calc(590em / 18);
    height: calc(420em / 18);
    max-width: 100%;
    margin: auto;
    margin-bottom: calc(20em / 18);

    &:hover {
      .grid-img__img {
        transform: scale(1.05);
      }
    }

    &__img {
      transition: .3s;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    color: var(--lime);
    margin-bottom: calc(8rem / 18);
  }

  &__text {
    font-weight: 600;
    margin-bottom: calc(20rem / 18);
  }
}

.modal {
  &-btn {
    position: absolute;
    right: 47px;
    top: 35px;

    @media only screen and (max-width: 768px){
      right: 20px;
      top: 20px;
    }
  }

  &-title {
    @media only screen and (max-width: 768px){
      margin-top: calc(67rem / 18);
    }
  }

  &-subtitle {
    margin-bottom: calc(40em / 18);
  }

  &-desc {
    margin-bottom: calc(40em / 18);

    h4 {
      text-transform: uppercase;
      border-bottom: 6px solid var(--text);
    }
  }

  &-table {
    h4 {
      text-transform: uppercase;
      border-bottom: 6px solid var(--text);
    }

    &-row {
      border-bottom: 1px solid var(--text);
      display: flex;
      margin-top: calc(20em / 18);
      padding-bottom: calc(3em / 18);
      justify-content: space-between;

      @media only screen and (max-width: 768px){
        flex-wrap: wrap;
      }

      &__key {
        flex: 0 0 calc(218em / 18);

        @media only screen and (max-width: 768px){
          flex-basis: 100%;
        }
      }

      &__value {
        flex: 0 1 calc(100% - 218em / 18 - 90em / 18);

        @media only screen and (max-width: 768px){
          flex-basis: 100%;
        }
      }
    }
  }
}

</style>

<style lang="scss">
.investments-modal-dialog {
  max-width: 1200px;

  .modal-content {
    background-color: var(--lime);
    color: var(--text);
    border-radius: 0;
  }

  .modal-body {
    padding: calc(66em / 18) calc(92em / 18);
    position: relative;

    @media only screen and (max-width: 768px){
      padding: calc(20em / 16);
    }
  }
}

.pagination {
  .page-item {

    &.active {
      .page-link {
        background-color: unset;
        color: var(--lime);
        font-weight: 500;
        position: relative;

        &:after {
          content: '';
          display: block;
          background-image: url('@/assets/img/icons/pagination-deco.svg');
          background-repeat: no-repeat;
          width: 38px;
          height: 38px;

          background-size: contain;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }


    &:first-child, &:last-child {
      display: none;
    }
  }

  .page-link {
    font-size: calc(19rem / 18);
    font-weight: 500;
    border: none;
    width: calc(36rem / 18);
    height: calc(36rem / 18);
    color: var(--lime);
    margin: 0 calc(7em / 19);
  }
}
</style>
