<template>
  <div class="banner">
    <div class="banner-img" v-if="banner.img">
      <img class="banner-img__img" :src="banner.img" :alt="banner.alt">
    </div>
    <div class="banner-overlay">
      <h1 class="banner-overlay__text">
        {{ banner.text }}
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Mcip-banner',
  props: {
    banner: Object
  }
}
</script>

<style lang="scss" scoped>
.banner {
  height: calc(290rem / 18);
  position: relative;

  @media only screen and (max-width: 768px){
    height: calc(140em / 18);
  }

  &-img {
    width: 100%;
    height: 100%;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(31, 44, 92, .5);

    &__text {
      color: var(--white);
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
}
</style>
