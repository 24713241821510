<template>
<div class="contact">
  <Banner :banner="response.banner"/>
  <Intro :title="response.intro.title" :text="response.intro.text" mod="lime"/>
  <Office :offices="response.offices" :apiKey="response.apiKey" />
</div>
</template>

<script>
import axios from 'axios'
import Banner from '@/components/Banner'
import Intro from '@/components/Intro'
import Office from '@/components/Office'

export default {
  name: "Mcip-Contact",
  metaInfo() {
    let meta = this.response.meta;
    return {
      title: meta ? meta.title : 'mcip',
      meta: [
        {
          name: 'description',
          content: meta ? meta.description : 'mcip'
        },
        {
          name: 'keywords',
          content: meta ? meta.keywords : 'mcip'
        },
        {
          proprty: 'og:image',
          content: meta ? meta.img : 'mcip'
        },
      ]
    }
  },
  components: {
    Banner,
    Intro,
    Office
  },
  data(){
    return ({
      response: {}
    })
  },
  methods: {
    fetchPageContent(){
      axios.get('/contact/getContent').then(
        res=>{
          this.response = res.data;
        }
      )
    }
  },
  created(){
    this.fetchPageContent()
  }
}
</script>

<style lang="scss" scoped>
</style>
