<template>
<div class="office">
  <div class="office-row" v-for="office in offices" :key="office.lat">
    <div class="office-row__detail">
      <h3 class="office-row__name">{{ office.name }}</h3>
      <div class="office-row__address">{{ office.address1 }}</div>
      <div class="office-row__address">{{ office.address2 }}</div>
      <div class="office-row__address">{{ office.address3 }}</div>
      <div class="office-row__tel" v-if="office.tel">Phone: {{ office.tel }}</div>
    </div>
    <div class="office-row__map">
      <GoogleMap ref="googleMap" :lat="office.lat" :lng="office.lng" :mklat="office.mklat" :mklng="office.mklng" :title="office.name" :loaded="isLoaded"/>
    </div>
  </div>
</div>
</template>

<script>
import GoogleMap from '@/components/GoogleMap'

export default {
  name: 'Mcip-Office',
  props: {
    offices: Array,
    apiKey: String
  },
  data(){
    return ({
      isLoaded: false,
    })
  },
  components: {
    GoogleMap
  },
  computed: {
    scriptSrc(){
      return `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}`;
    }
  },
  methods: {
    appendScript(){
      let scriptTag = document.createElement("script");

      scriptTag.onload = ()=>{
        this.isLoaded = true;
      }

      scriptTag.src = this.scriptSrc;

      document.getElementsByTagName("head")[0].appendChild(scriptTag);
    },
  },
  mounted(){
    this.appendScript();
  }
}
</script>

<style lang="scss" scoped>
.office {
  max-width: 1440px;
  margin: auto;

  &-row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    height: calc(470em / 18);

    &:nth-child(even){
      .office-row__detail {
        order: 2;
      }
    }

    @media only screen and (max-width: 992px){
      display: block;
      height: auto;

      & + & {
        margin-top: calc(75em / 16);
      }
    }

    &__detail {
      display: flex;
      flex-direction: column;
      justify-content: center;

      padding: 0 calc(110em / 18);

      h3 {
        color: var(--lime);
      }

      @media only screen and (max-width: 992px){
        padding: calc(20em / 16);
      }
    }

    &__name {
      padding-bottom: calc(25rem / 18);
      margin-bottom: calc(20rem / 18);
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 90px;
        height: 0;
        border-bottom: 4px solid var(--blue);
      }
    }

    &__tel {
      margin-top: calc(2em);
    }

    &__map {
      min-height: calc(200em / 16);

      @media only screen and (max-width: 992px){
        height: calc(400em / 18);
      }

      @media only screen and (max-width: 768px){
        height: calc(200em / 16);
      }
    }
  }
}
</style>
